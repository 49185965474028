#ccContainer,
    #cvvContainer{
    padding: 5px;
    color: #232323;
    font-size: 1rem;
    line-height: 1.25;
    height: 2.5rem;
    border: 0.0625rem solid #dadada;
    }
	
body	.paypal select {
border: 0.0625rem solid #dadada !important;
}
p.errorstext{
font-size: 14px;
margin-top: 5px;
font-family: "Fira Sans","Helvetica Neue",Helvetica,"Roboto",Arial,sans-serif;
}
